import { Constants, errorLogger } from '../../../../lib/ads/utils'

const LAZYLOAD_OFFSET = 600 // How many px in advance to load the placement

const STATUS = {
  Initialized: 0,
  Created: 1,
  Rendered: 2,
  Empty: 3
}

export default (() =>
  class TaboolaPosition {
    constructor(config, elem) {
      if (!config) {
        return
      }
      this.id = config.id
      this.config = config
      this.elem = elem || document.getElementById(this.id)
      this.status = STATUS.Initialized
    }

    static fromElement(elem) {
      const logger = errorLogger()
      if (!elem) {
        return logger(`[Taboola ${Constants.ERROR_LEVEL.WARN}] Element not found`)
      }
      let elemConfig
      try {
        elemConfig = JSON.parse(elem.dataset.config)
      } catch (e) {
        return logger(
          `[Taboola ${Constants.ERROR_LEVEL.ERROR}] Error parsing config for', ${elem?.id}`
        )
      }
      return new TaboolaPosition(elemConfig, elem)
    }

    get placement() {
      return this.config.placement
    }

    get region() {
      return this.config.region || 'index'
    }

    get isRendered() {
      return this.status === STATUS.Rendered
    }

    create() {
      if (this.status === STATUS.Created) {
        return
      }
      window.benji?.render?.({ [this.id]: this.getConfig() })
      this.status = STATUS.Created
    }

    getConfig() {
      return {
        id: this.id,
        mode: this.config.mode,
        container: this.id,
        placement: this.config.placement,
        targetType: this.config.targetType,
        region: this.region
      }
    }

    handleWindowScroll() {
      if (this.status !== STATUS.Initialized) {
        return
      }
      if (this._validateViewability()) {
        this.create()
      }
    }

    onRender(event) {
      this.status = STATUS.Rendered
    }

    onNoContent(event) {
      this.status = STATUS.Empty
      this.onEmptyCallback?.(event)
    }

    setOnEmptyCallback(fn = () => {}) {
      this.onEmptyCallback = fn
    }

    _validateViewability() {
      // Viewability checks only matter before the ad loads for the first time and for lazy regions
      if (this.status !== STATUS.Initialized || this.config.region !== 'lazy') {
        return true
      }
      // Lazy loaded ads should only initialize if they are within window.innerHeight*2
      const rect = this.elem.getBoundingClientRect()
      return rect.top < window.innerHeight + LAZYLOAD_OFFSET
    }
  })()
